@import "../../utils/Variable.scss";

.navbar-con {
  width: 100%;
  margin: auto;
  height: 5rem;
  background-color: $primary;
  position: fixed;
  top: 0;
  z-index: 1;

  box-shadow: 0 0 3px #202837;

  display: flex;

  .logo-holder {
    flex: 25%;
    @include flex-center;
    // background-color: red;

    img {
      width: 40px;
      object-fit: contain;
      object-position: center;
    }

    .logo-txt-group {
      @include flex-center;
      font-family: monospace;
      padding-left: 10px;
      span {
        font-size: 1.2rem;
      }
      .dash {
        font-weight: 100;
        color: $txt-white-secondary;
      }
    }
  }
  .navigation-holder {
    color: white;
    flex: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    .nav-item {
      color: $txt-white-secondary;
      cursor: pointer;
      padding: 0.2rem 1.8rem;
      border-radius: 0.2rem;
      font-weight: bold;
      transition: 0.2s;
      font-size: 0.9rem;
      text-decoration: none;
    }

    .active {
      color: $txt-dark-primary;
      background-color: $txt-primary;
    }
  }
  .info-holder {
    flex: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;

    span {
      color: $txt-dark-primary;
      background-color: $txt-primary;
      padding: 0.5rem 2rem;
      border-radius: 6px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
