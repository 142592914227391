@import "../../utils/Variable.scss";

.register-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
  z-index: 2;

  display: flex;
  flex-direction: column;

  .register-con {
    width: 50%;
    height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 2rem;
    border-radius: 6px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    background-color: $tertiary;

    .navigation {
      flex: 10%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        flex: 100%;
        color: $txt-white-secondary;
        text-align: center;
        font-weight: bold;
        font-family: monospace;
        font-size: 1.2rem;
      }
    }
    .img-holder-qr {
      flex: 60%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      //   background-color: red;

      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
        border-radius: 6px;
        object-position: center;
        box-shadow: 0 0 5px $txt-white-secondary;
      }

      p {
        margin-top: 2rem;
        color: $txt-white-primary;
      }

      a {
        margin-top: 1rem;
        color: $txt-primary;
      }
    }

    .content-holder {
      flex: 30%;
      //   background-color: rebeccapurple;
      @include flex-center;
      align-items: center;

      h1 {
        color: $txt-white-primary;
        font-style: italic;

        span {
          color: $txt-primary;
        }
      }
    }
    .img-holder {
      position: absolute;
      right: 1rem;
      top: 1rem;
      img {
        width: 20px;
        height: auto;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .register-holder {
    .register-con {
      box-shadow: 0 0 5px 5px $txt-dark-primary;
      width: 80%;
      height: 80%;
      // background-color: green;
      .navigation {
        // background-color: red;
        padding-top: 1rem;
      }
    }
  }
}
