@import "../../utils/Variable.scss";

.navbar-con {
  width: 100%;
  margin: auto;
  height: 5rem;
  background-color: $primary;

  display: flex;

  .logo-holder {
    flex: 30%;
    @include flex-center;
    font-weight: bold;
    color: $txt-white-primary;

    span {
      font-size: 1rem;
    }
  }

  .menu-btn-holder {
    flex: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;

    .btn-holder {
      width: 30px;
      height: 30px;
      // background-color: red;

      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
        transition: 0.2s;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .navbar-con {
    .logo-holder {
      flex: 50%;
      // background-color: rebeccapurple;
      justify-content: flex-start;
      padding-left: 1rem;

      span{
        padding-left: 0.5rem;
      }
    }

    .menu-btn-holder {
      .btn-holder{
        background-color: $txt-primary;
        width: 6rem;
        border-radius: 6px;
        @include flex-center;
        font-weight: bold;
      }
      flex: 50%;
    }
  }
}
