@import "./utils/Variable.scss";
@import url("https://fonts.googleapis.com/css?family=Manrope");

html{
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  background-color: $primary;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

.main {
  width: 100vw;
  height: 100vh;
  background-color: $primary;
  position: relative;

  background-image: url("./assets/bg-hero.svg");
  object-fit: contain;
  object-position: center;
}
