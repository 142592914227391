@import "../../utils/Variable.scss";

.main-holder {
  width: 100%;
  height: 200px;
  margin-top: calc(5rem + 30px);
  @include flex-center;
  padding: 0 8rem;
  flex-direction: column;

  h2 {
    font-size: 1.5rem;

    span {
      color: $txt-white-primary;
      font-style: italic;
    }
  }
}
@media screen and (max-width: 992px) {
  .main-holder {
    margin-top: 3rem;
    padding: 0 2rem;
  }
}

.card-holder {
  width: 100%;
  height: fit-content;
  padding: 0 8rem;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .info-card-holder {
    width: 11rem;
    height: 11rem;
    background-color: $tertiary;
    border-radius: 6px;
    border: 1px solid #202837;
    padding: 0.5rem;

    display: flex;
    flex-direction: column;
  }

  .card-num {
    flex: 50%;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    p {
      font-size: 3rem;
      color: $txt-primary;
      font-family: monospace;
    }
  }
  .card-txt {
    flex: 50%;
    padding: 0 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      font-weight: bold;
      font-family: monospace;
      font-size: 1rem;
      color: $txt-white-secondary;
    }
  }
}
@media screen and (max-width: 992px) {
  .card-holder {
    flex-direction: column;
    padding: 0;

    .info-card-holder {
      margin-top: 1rem;
    }
    .info-card-holder:first-child {
      margin-top: 0;
    }
  }
}

.aboutus-holder {
  width: 100%;
  height: 400px;
  margin-top: 12rem;
  padding: 0 8rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  h1 {
    color: $txt-white-primary;
  }

  .aboutus-con {
    width: 100%;
    height: fit-content;
    background-color: $quadtiary;
    border-radius: 6px;
    display: flex;
    padding: 0.8rem;

    .img-holder {
      flex: 30%;
      position: relative;

      @include flex-center;

      img {
        position: absolute;
        width: 14rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .content-holder {
      flex: 70%;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: $txt-white-primary;
        font-family: monospace;
        font-size: 1rem;

        span {
          color: $txt-primary;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .aboutus-holder {
    height: fit-content;
    // background-color: green;
    margin-top: 2rem;
    padding: 2rem;

    h1 {
      font-size: 1.5rem;
      text-align: center;
    }

    .aboutus-con {
      flex-direction: column;
      height: fit-content;
      margin-top: 2rem;
      padding: 1rem;

      .img-holder {
        img {
          width: 13rem;
          position: static;
          transform: none;
        }
      }
      .content-holder {
        justify-content: center;
        align-items: flex-start;
        justify-content: center;
        padding: 1rem 0;
        // background-color: red;

        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.learning-con {
  width: 100%;
  height: 56rem;
  background: linear-gradient(180deg, #111927, #0b121f 27.16%);
  margin-top: 3rem;
  padding: 0 0.8rem 2rem;

  display: flex;
  flex-direction: column;

  .learning-title-holder {
    flex: 25%;
    // background-color: red;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      font-weight: bold;
      font-size: 2rem;
      color: $txt-white-primary;
      margin-top: 1.8rem;
    }

    img {
      width: 3rem;
      object-fit: contain;
      object-position: center;
    }
  }

  .learning-content-holder {
    flex: 75%;
    // background-color: aliceblue;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 2rem;

    .learning-card {
      width: 20rem;
      height: 100%;
      background-color: $secondary;
      border-radius: 6px;
      padding: 1rem;

      display: flex;
      flex-direction: column;

      .learning-card-img-holder {
        flex: 35%;
        @include flex-center;

        img {
          width: 12rem;
          height: auto;
          object-fit: contain;
          object-position: center;
        }
      }

      .learning-card-content-holder {
        flex: 65%;
        // background-color: antiquewhite;

        .learning-header {
          width: 100%;
          height: 3rem;
          @include flex-center;

          // background-color: red;
          h3 {
            text-align: center;
            color: $txt-white-primary;
          }
        }

        .learning-content {
          // background-color: rebeccapurple;
          width: 100%;
          height: fit-content;
          padding: 2rem 2rem;

          ol {
            list-style-position: outside;
            list-style-type: circle;

            li {
              color: $txt-white-secondary;
              margin-top: 10px;
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .learning-con {
    height: fit-content;
    padding: 0 2rem 2rem;

    .learning-content-holder {
      flex-direction: column;

      .learning-card {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem 0;

        .learning-card-content-holder {
          padding: 0 0.3rem;
          .learning-header {
            // background-color: red;
            height: 5rem;
            h3 {
              font-size: 1.5rem;
            }
          }
          .learning-content {
            padding: 1rem 2rem 2rem;

            ol {
              li {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
      .learning-card:first-child {
        margin-top: none;
      }
    }
  }
}

.highlight {
  width: 100%;
  height: fit-content;
  background-color: #0b121f;
  // background-color: red;
  padding: 1rem 0.8rem 2rem;

  h1 {
    text-align: center;
    color: $txt-white-primary;
  }

  .highlight-holder {
    width: 80%;
    height: 200px;
    margin: auto;
    margin-top: 2rem;
    // background-color: red;
    border: 1px solid #202837;
    // padding: 2rem;
    border-radius: 6px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
    }

    .highlight-con {
      width: 100%;
      height: 100%;
      padding: 2rem;

      @include flex-center;

      h1 {
        font-size: 3rem;
        font-weight: bolder;
        color: $txt-primary;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .highlight {
    padding: 1rem 0;

    .highlight-holder {
      margin-top: 0;

      .highlight-con {
        padding: 0;

        h1 {
          z-index: 2;
          font-size: 2.3rem;
        }
      }
    }
  }
}

#reviews {
  margin-bottom: 5rem;
}

.footer {
  width: 100%;
  height: 11rem;
  background-color: $tertiary;
  padding: 0 9rem 2rem;

  display: flex;

  .brand-info {
    flex: 40%;

    display: flex;
    flex-direction: column;

    .brand-logo {
      flex: 60%;
      @include flex-center;
      align-items: center;
      // background-color: red;

      img {
        width: 40px;
        object-fit: contain;
        object-position: center;
      }

      .logo-txt-group {
        @include flex-center;
        padding-left: 10px;
        span {
          font-size: 1.5rem;
          color: $txt-white-primary;
        }
        .dash {
          font-weight: 100;
          color: $txt-white-secondary;
        }
      }
    }
    .brand-social-con {
      flex: 40%;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      a {
        width: 30px;
        height: 30px;
        margin: 0 10px;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
  .brand-address {
    flex: 60%;

    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      color: $txt-white-secondary;
      margin-top: 10px;
      span {
        font-weight: bold;
        color: $txt-primary;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .footer {
    height: fit-content;
    padding: 1rem 1rem;
    flex-direction: column;

    .brand-info {
      .brand-social-con {
        padding: 0.3rem 0;
      }
    }

    .brand-address {
      padding: 1rem 0 1rem;
      p {
        font-size: 1.1rem;
      }
    }
  }
}
