$primary: #111927;
$secondary: #1a2332;
$tertiary: #0b121f;
$quadtiary: #202837;
$txt-primary: #9fef00;
$txt-white-primary: #ffffff;
$txt-white-secondary: #a4b1cd;
$txt-dark-primary: #141d2B;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
