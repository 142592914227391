@import "../../utils/Variable.scss";

.reviews-holder {
  width: 100%;
  height: 600px;
  background-color: $primary;
  display: flex;
  flex-direction: column;

  .review-title-holder {
    flex: 20%;
    width: 100%;
    display: flex;
    padding: 0 9rem;

    .review-title-content {
      flex: 70%;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;

      h1 {
        color: $txt-white-primary;
        font-weight: bolder;
      }
    }
    .review-title-btn {
      flex: 30%;
      width: 100%;
      height: 100%;
      @include flex-center;
      justify-content: flex-end;

      button {
        background-color: $txt-white-primary;
        border: none;
        padding: 1.2rem;
        border-radius: 6px;
        cursor: pointer;
        font-size: 1.5rem;
      }
      button:first-child {
        margin-right: 10px;
      }
    }
  }

  .review-con {
    flex: 80%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .review-slider {
      width: 80%;
      overflow-x: auto;
      display: flex;
      scroll-behavior: smooth;

      .review-card {
        flex: 0 0 auto;
        width: 31.25rem;
        height: 25rem;
        border-radius: 6px;
        margin-right: 10px;
        padding: 2rem;
        background-color: $tertiary;
        display: flex;
        flex-direction: column;

        .card-content {
          flex: 80%;

          p {
            color: $txt-white-secondary;
            font-size: 1rem;
            line-height: 2rem;
          }
        }
        .card-info {
          flex: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;

          h3 {
            color: $txt-white-primary;
          }
        }
      }
    }

    .slider-controls {
      margin-top: 10px;

      .slider-controls button {
        margin: 0 5px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .reviews-holder {
    .review-title-holder {
      padding: 0 2rem;

      .review-title-content {
        // background-color: red;
        padding: 0 0.5rem;
        h1 {
          font-size: 1.1rem;
          font-weight: normal;
        }
      }
    }

    .review-con {
      padding: 2rem 0;
      .review-slider {
        .review-card {
          width: 20rem;
          height: 30rem;
        }
      }
    }
  }
}

.review-slider {
  width: 80%; /* Adjust width as needed */
  overflow-x: auto;
  display: flex;
  scroll-behavior: smooth; /* Enable smooth scrolling */
  -webkit-overflow-scrolling: touch; /* Enable momentum scrolling on iOS */
}

.review-slider::-webkit-scrollbar {
  display: none; /* Hide the scrollbar for WebKit (Chrome, Safari, etc.) */
}
